import React, { useContext, useEffect } from 'react';

import LobbyLayout from '@components/LobbyLayout';
import StyledRouter from '@components/StyledRouter';
import UserContext from '@contexts/UserContext';

const withLobbyLayout = (Component) => (props) => {
  return (
    <LobbyLayout>
      <Component {...props} />
    </LobbyLayout>
  );
};

const SessionExpired = () => {
  const { connected, disconnectWallet } = useContext(UserContext);

  useEffect(() => {
    if (connected) {
      disconnectWallet()
    }
  }, [])
  return (
    "Your session expired. Please re-connect your wallet and sign the nonce to authenticate!"
  )
}

const SessionExpiredLayout = withLobbyLayout(SessionExpired);

const Sessions = () => {
  return (
    <StyledRouter basepath="/sessions">
      <SessionExpiredLayout path="/expired" />
    </StyledRouter>
  );
};

export default Sessions;
